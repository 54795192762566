export const EN_XENIUM_FOOTER_DATA = {
  title: "Xenium In Situ",
  items: [
    {
      label: "Start Here",
      links: [
        {
          target: "_self",
          label: "Platform Overview",
          url: "/platforms/xenium",
        },
      ],
    },
    {
      label: "Xenium Products",
      links: [
        {
          target: "_self",
          label: "Xenium Gene Expression Panels",
          url: "/products/xenium-panels",
        },
      ],
    },
    {
      label: "Xenium Instruments",
      links: [
        {
          target: "_self",
          label: "Xenium Analyzer",
          url: "/instruments/xenium-analyzer",
        },
      ],
    },
    {
      label: "Analysis",
      links: [
        {
          target: "_self",
          label: "Xenium Analysis",
          url: "/products/xenium-analysis",
        },
      ],
    },
    {
      label: "Resources",
      links: [
        {
          target: "_self",
          label: "Xenium User Guides",
          url: "/support/user-guides/in-situ-gene-expression?menu%5BproductNames%5D=In%20Situ%20Gene%20Expression",
        },
        {
          target: "_self",
          label: "Xenium Support Documentation",
          url: "/support/in-situ-gene-expression",
        },
        {
          target: "_self",
          label: "Xenium Datasets",
          url: "/datasets?query=&page=1&configure%5BhitsPerPage%5D=50&configure%5BmaxValuesPerFacet%5D=1000&refinementList%5Bproduct.name%5D%5B0%5D=In%20Situ%20Gene%20Expression",
        },
        {
          target: "_self",
          label: "Xenium Publications",
          url: "/publications?refinementList%5BproductGroups%5D%5B0%5D=In%20Situ%20Gene%20Expression&page=1",
        },
      ],
    },
    {
      label: "Store",
      links: [
        {
          target: "_self",
          label: "Get Xenium Pricing",
          url: "/store/product-catalog",
        },
      ],
    },
  ],
};
