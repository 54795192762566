import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Icon from "../Icon";
import type { IconSource } from "../Icon/types";
import Text from "../Text";
import { Theme } from "./types";
import { getThemeStyles } from "./utils";

interface Props {
  section: {
    label: string;
    links: Array<{
      label: string;
      target?: string;
      url: string;
    }>;
  };
  theme?: Theme;
}

const icons: Record<string, [IconSource, string]> = {
  facebook: ["facebook", "Facebook"],
  linkedin: ["linkedin", "LinkedIn"],
  twitter: ["twitter", "Twitter"],
  youtube: ["youtube", "Youtube"],
};

const IconLinks: FunctionComponent<Props> = ({
  section,
  theme = Theme.dark,
}) => {
  const { links } = section;
  const { labelColor, iconHover } = getThemeStyles(theme);
  return (
    <div>
      {section.label && (
        <Text
          as={"h3"}
          color={labelColor}
          css={css`
            margin-bottom: 1rem;
          `}
          size={"xsmall"}
          weight={"semibold"}
        >
          {section.label}
        </Text>
      )}

      <ul
        css={css`
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;
        `}
      >
        {links?.map((link) => {
          const { label, target, url } = link;

          if (!label || !url) {
            return null;
          }

          const [iconName, title] = icons[label];

          return (
            <li key={title}>
              <Anchor
                color={"gray"}
                hoverColor={iconHover}
                href={url}
                target={target || undefined}
                title={title}
                underlineOnHover={true}
              >
                <Icon
                  color={"inherit"}
                  css={{ marginRight: "16px" }}
                  size={"20px"}
                  source={iconName}
                />
              </Anchor>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default IconLinks;
