import { fontSizeXsmall } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import { buttonCss } from "../Button";
import Grid from "../Grid";
import { SpanifiedText } from "../SpanifiedText";
import Text from "../Text";
import { SUBSCRIBE_URL } from "./constants";
import { Theme } from "./types";
import { getThemeStyles } from "./utils";

interface Props {
  section: {
    label: string;
    links: Array<{
      label: string;
      target?: string;
      url: string;
    }>;
  };
  theme?: Theme;
}

const ContactLinks: FunctionComponent<Props> = ({
  section,
  theme = Theme.dark,
}) => {
  const { links } = section;

  const subscribe = links.find((link) => link.url === SUBSCRIBE_URL);

  const linksWithoutSubscribe = links.filter(
    (link) => link.url !== SUBSCRIBE_URL,
  );

  const { textColor, textHoverColor, labelColor } = getThemeStyles(theme);

  return (
    <Grid
      css={css`
        align-self: start;

        width: 100%;
      `}
      gap={"1rem"}
    >
      {section.label && (
        <Text as={"h3"} color={labelColor} size={"xsmall"} weight={"semibold"}>
          {section.label}
        </Text>
      )}
      <ul
        css={css`
          display: grid;
          gap: 1rem;
          list-style: none;
          margin: 0;
          padding: 0;
        `}
      >
        {linksWithoutSubscribe.map((link) => {
          const { label, target, url } = link;

          return (
            <li key={label}>
              {label.endsWith("@10xgenomics.com") ? (
                <Anchor
                  color={textColor}
                  hoverColor={textHoverColor}
                  href={url || "/"}
                  target={target || undefined}
                >
                  <Text as={"span"} color={"inherit"} size={"xsmall"}>
                    {label}
                  </Text>
                </Anchor>
              ) : (
                <Anchor
                  href={url}
                  color={textColor}
                  hoverColor={textHoverColor}
                >
                  {/*
              This is necessary to prevent a Google AdWords script that we do not control from adding addtional `+` symbols
              at the start of the phone number every time the page comes into focus.
            */}
                  <SpanifiedText as={"span"} color={"inherit"} size={"xsmall"}>
                    {label}
                  </SpanifiedText>
                </Anchor>
              )}
            </li>
          );
        })}
      </ul>
      {subscribe && (
        <Anchor
          color={textColor}
          hoverColor={textHoverColor}
          css={[
            buttonCss({}),
            {
              fontSize: fontSizeXsmall,
              padding: "0 1rem",
              maxWidth: "298px",
              width: "100%",
            },
          ]}
          href={subscribe.url || "/"}
          target={subscribe.target || undefined}
        >
          {subscribe.label}
        </Anchor>
      )}
    </Grid>
  );
};

export default ContactLinks;
