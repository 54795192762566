import type { Locale } from "@10xdev/cms/types";
import type { TextColor } from "@10xdev/types";
import { CN_FOOTER_DATA, EN_FOOTER_DATA, JP_FOOTER_DATA } from "./constants";

import {
  colorBlueDark,
  colorBlueLighter,
  colorBlueLightest,
  colorGrayLightest,
  colorSteelDark,
  colorSteelDarker,
  colorSteelDarkest,
  colorSteelLighter,
  colorWhite,
} from "@10xdev/design-tokens";

import { Theme } from "./types";

type ModeStyles = {
  backgroundColor: string;
  borderColor: string;
  labelColor: TextColor;
  textColor: TextColor;
  textHoverColor: string;
  tmColor: TextColor;
  iconHover: string;
  highlightColor: string;
  platformBackgroundColor: string;
  topBorderColor: string;
};

export const getThemeStyles = (theme: Theme): ModeStyles => {
  return theme === Theme.dark
    ? {
        backgroundColor: colorSteelDarkest,
        platformBackgroundColor: colorSteelDarkest,
        borderColor: colorSteelDark,
        labelColor: "white",
        textColor: "blueLight",
        textHoverColor: colorWhite,
        tmColor: "steelLight",
        iconHover: colorWhite,
        highlightColor: "rgba(68, 89, 121, 0.5)",
        topBorderColor: colorSteelDarker,
      }
    : {
        backgroundColor: colorGrayLightest,
        platformBackgroundColor: colorBlueLightest,
        borderColor: colorSteelLighter,
        labelColor: "steelDarkest",
        textColor: "blue",
        textHoverColor: colorBlueDark,
        tmColor: "steelDarker",
        iconHover: colorSteelDarker,
        topBorderColor: colorSteelLighter,
        highlightColor: colorBlueLighter,
      };
};

export const getDataForLocale = (locale?: Locale) => {
  switch (locale) {
    case "en":
      return EN_FOOTER_DATA;
    case "jp":
      return JP_FOOTER_DATA;
    case "cn":
      return CN_FOOTER_DATA;
    default:
      return EN_FOOTER_DATA;
  }
};
