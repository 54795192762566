import { Text } from "../Text/Text";

interface SpanifiedTextProps
  extends Omit<React.ComponentProps<typeof Text>, "children"> {
  children: string;
  separator?: string;
}

export const SpanifiedText: React.FC<SpanifiedTextProps> = ({
  children,
  separator = " ",
  ...rest
}) => {
  const segments = children.split(separator);
  return (
    <Text {...rest}>
      {segments.map((segment, index) => (
        <span key={index}>
          {segment}
          {index < segments.length - 1 ? <span>{separator}</span> : <></>}
        </span>
      ))}
    </Text>
  );
};
