export const JP_VISIUM_FOOTER_DATA = {
  title: "Visium Spatial",
  items: [
    {
      label: "Start Here",
      links: [
        {
          target: "_self",
          label: "Platform Overview",
          url: "/platforms/visium",
        },
      ],
    },
    {
      label: "Visium Products",
      links: [
        {
          target: "_self",
          label: "HD Spatial Gene Expression",
          url: "/products/visium-hd-spatial-gene-expression",
        },
        {
          target: "_self",
          label: "Spatial Gene Expression",
          url: "/products/spatial-gene-expression",
        },
        {
          target: "_self",
          label: "Spatial Gene + Protein Expression",
          url: "/products/spatial-gene-and-protein-expression",
        },
      ],
    },
    {
      label: "Visium Instruments",
      links: [
        {
          target: "_self",
          label: "Visium CytAssist",
          url: "/instruments/visium-cytassist",
        },
      ],
    },
    {
      label: "Analysis",
      links: [
        {
          target: "_self",
          label: "Visium Analysis",
          url: "/products/visium-analysis",
        },
      ],
    },
    {
      label: "Resources",
      links: [
        {
          target: "_self",
          label: "Visium User Guides",
          url: "/support/user-guides",
        },
        {
          target: "_self",
          label: "Visium Support Documentation",
          url: "/support",
        },
        {
          target: "_self",
          label: "Visium Datasets",
          url: "/datasets?query=&page=1&configure%5BhitsPerPage%5D=50&configure%5BmaxValuesPerFacet%5D=1000&refinementList%5Bproduct.name%5D%5B0%5D=CytAssist%20Spatial%20Gene%20and%20Protein%20Expression&refinementList%5Bproduct.name%5D%5B1%5D=Spatial%20Gene%20Expression&refinementList%5Bproduct.name%5D%5B2%5D=HD%20Spatial%20Gene%20Expression",
        },
        {
          target: "_self",
          label: "Visium Publications",
          url: "/publications?refinementList%5BproductGroups%5D%5B0%5D=Spatial%20Gene%20Expression%20for%20Fresh%20Frozen&refinementList%5BproductGroups%5D%5B1%5D=Spatial%20Gene%20Expression%20for%20FFPE&page=1",
        },
      ],
    },
    {
      label: "Store",
      links: [
        {
          target: "_self",
          label: "Price a Visium Experiment",
          url: "/store/experiment-builder",
        },
      ],
    },
  ],
};
