import { css } from "@emotion/react";
import type { Property } from "csstype";
import type { ReactNode } from "react";

import type { WithResponsive } from "../../utils/media-queries";
import { generateResponsiveCSS } from "../../utils/media-queries";

interface GridItemProps {
  children?: ReactNode;
  className?: string;
  gridColumn?: WithResponsive<Property.GridColumn>;
  gridColumnEnd?: WithResponsive<Property.GridColumnEnd>;
  gridColumnStart?: WithResponsive<Property.GridColumnStart>;
  gridRow?: WithResponsive<Property.GridRow>;
  gridRowEnd?: WithResponsive<Property.GridRowEnd>;
  gridRowStart?: WithResponsive<Property.GridRowStart>;
  display?: WithResponsive<Property.Display>;
}

function GridItem({ children, className, ...styles }: GridItemProps) {
  const cssString = generateResponsiveCSS({ ...styles });
  return (
    <div
      className={className}
      css={css`
        ${cssString}
      `}
    >
      {children}
    </div>
  );
}

export default GridItem;
