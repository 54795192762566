export enum Theme {
  light = "light",
  dark = "dark",
}

export type Platform = "chromium" | "visium" | "xenium";

export interface FooterSection {
  label: string;
  links: Array<{
    label: string;
    target?: string;
    url: string;
    className?: string;
  }>;
}

export interface FooterData {
  title?: string;
  items: FooterSection[];
}
