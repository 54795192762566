import { Locale } from "@10xdev/cms/types";
import { layoutWidth1200, sizeXlarge } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import Flex from "../Flex";
import Grid from "../Grid";
import GridItem from "../Grid/GridItem";
import Text from "../Text";
import { PRODUCT_FOOTER_DATA } from "./constants";
import Links from "./Links";
import { Platform, Theme } from "./types";
import { getThemeStyles } from "./utils";

interface Props {
  theme?: Theme;
  locale?: Locale;
  platform: Platform;
}

const PlatformFooter = ({ theme = Theme.dark, locale, platform }: Props) => {
  const { platformBackgroundColor, labelColor, topBorderColor } =
    getThemeStyles(theme);

  const { items, title } = PRODUCT_FOOTER_DATA[platform][locale || "en"];

  const [
    startHere,
    chromiumProducts,
    chromiumInstruments,
    analysis,
    resources,
    store,
  ] = items;

  return (
    <footer
      css={css`
        align-items: center;
        background-color: ${platformBackgroundColor};
        border-top: 1px solid ${topBorderColor};
        box-sizing: border-box;
        padding-bottom: 2rem;
        display: flex;
        flex-direction: column;
        padding: ${sizeXlarge};
        width: 100%;
      `}
    >
      <div
        css={css`
          max-width: ${layoutWidth1200};
          width: 100%;
        `}
      >
        <Grid
          gridTemplateColumns={{
            base: "1fr 1fr",
            tabletPortrait: "1fr 1fr 1fr",
            tabletLandscape: "1fr 1fr 1fr auto",
            desktop: "repeat(3, 175px) auto",
          }}
          gap={"30px"}
          css={css`
            padding-bottom: 2.5rem;
          `}
          gridAutoRows={"max-content"}
        >
          <Flex>
            <Text
              as={"div"}
              size={"xlarge"}
              weight={"semibold"}
              color={labelColor}
            >
              {title}
            </Text>
          </Flex>
          <Flex flexDirection={"column"} gap={"2rem"}>
            {startHere && (
              <Links highlight={true} section={startHere} theme={theme} />
            )}
            {chromiumProducts && (
              <Flex
                display={{
                  base: "none",
                  tabletPortrait: "flex",
                  tabletLandscape: "none",
                }}
              >
                <Links section={chromiumProducts} theme={theme} />
              </Flex>
            )}
          </Flex>
          <GridItem
            gridColumnStart={{
              base: "initial",
              tabletPortrait: "2",
              tabletLandscape: "3",
              desktop: "initial",
            }}
            gridRowStart={{
              base: "initial",
              tabletPortrait: "2",
              tabletLandscape: "initial",
            }}
            css={css`
              display: flex;
              flex-direction: column;
              gap: 2rem;
            `}
            display={{
              base: "block",
              tabletPortrait: "none",
              tabletLandscape: "block",
            }}
          >
            {chromiumProducts && (
              <Links section={chromiumProducts} theme={theme} />
            )}
          </GridItem>
          <GridItem
            gridColumnStart={{
              base: "initial",
              tabletPortrait: "3",
              tabletLandscape: "initial",
            }}
            gridRowStart={{
              base: "initial",
              tabletPortrait: "1",
              tabletLandscape: "initial",
            }}
          >
            <Grid
              gridTemplateColumns={{ base: "", desktop: "repeat(3, 175px)" }}
              gap={"30px"}
            >
              <Flex flexDirection={"column"} gap={"2rem"}>
                {chromiumInstruments && (
                  <Links section={chromiumInstruments} theme={theme} />
                )}
                {analysis && <Links section={analysis} theme={theme} />}
              </Flex>

              {resources && <Links section={resources} theme={theme} />}
              {store && <Links section={store} theme={theme} />}
            </Grid>
          </GridItem>
        </Grid>
      </div>
    </footer>
  );
};

export default PlatformFooter;
