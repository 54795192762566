import { FooterData } from "../types";

export const EN_FOOTER_DATA: FooterData = {
  items: [
    {
      label: "Company",
      links: [
        {
          target: "_self",
          label: "About us",
          url: "/company",
        },
        {
          target: "_self",
          label: "Investors",
          url: "https://investors.10xgenomics.com/overview/default.aspx",
        },
        {
          target: "_self",
          label: "Careers",
          url: "https://careers.10xgenomics.com/careers",
        },
        {
          target: "_self",
          label: "Contact",
          url: "/contact",
        },
        {
          target: "_self",
          label: "News",
          url: "/news",
        },
        {
          target: "_self",
          label: "Distributors",
          url: "/distributors",
        },
      ],
    },
    {
      label: "Platforms",
      links: [
        {
          target: "_self",
          label: "Chromium Single Cell",
          url: "/platforms/chromium",
        },
        {
          target: "_self",
          label: "Visium Spatial",
          url: "/platforms/visium",
        },
        {
          target: "_self",
          label: "Xenium In Situ",
          url: "/platforms/xenium",
        },
      ],
    },
    {
      label: "Resources",
      links: [
        {
          target: "_self",
          label: "Datasets",
          url: "/datasets",
        },
        {
          target: "_self",
          label: "Publications",
          url: "/publications",
        },
        {
          target: "_self",
          label: "Support Hub",
          url: "/support",
        },
        {
          target: "_self",
          label: "Blog",
          url: "/blog",
        },
        {
          target: "_self",
          label: "Compatible Products",
          url: "/compatible-products",
        },
      ],
    },
    {
      label: "Legal Notices",
      links: [
        {
          target: "_self",
          label: "Privacy Policy",
          url: "/legal/privacy-policy",
        },
        {
          target: "_self",
          label: "Terms of Use",
          url: "/legal/terms-of-use",
        },
        {
          target: "_self",
          url: "/legal/legal-notices",
          label: "Other Legal Notices",
        },
      ],
    },
    {
      label: "Manage Preferences",
      links: [
        {
          target: "_self",
          label: "Email Preferences",
          url: "https://pages.10xgenomics.com/subscription.html",
        },
        {
          target: "_self",
          label: "Manage Cookie Preferences",
          url: "#",
          className: "cky-banner-element",
        },
      ],
    },
    {
      label: "Questions? We're here to help",
      links: [
        {
          target: "_self",
          label: "support@10xgenomics.com",
          url: "mailto:support@10xgenomics.com",
        },
        {
          target: "_self",
          label: "+1 925 401 7300",
          url: "tel:+19254017300",
        },
        {
          target: "_self",
          label: "Sign Up for Product and Support Updates",
          url: "/subscribe",
        },
      ],
    },
    {
      label: "Follow us on social media",
      links: [
        {
          target: "_blank",
          label: "linkedin",
          url: "https://www.linkedin.com/company/10xgenomics/",
        },
        {
          target: "_blank",
          label: "twitter",
          url: "https://twitter.com/10xgenomics",
        },
        {
          target: "_blank",
          label: "youtube",
          url: "https://www.youtube.com/channel/UCx3Dfre5IutNPuLS4ufC9mw",
        },
        {
          target: "_blank",
          label: "facebook",
          url: "https://www.facebook.com/10xGenomics/",
        },
      ],
    },
  ],
};
