import { Locale } from "@10xdev/cms/types";
import { FooterData, Platform } from "../types";
import { EN_CHROMIUM_FOOTER_DATA } from "./chromium/en";
import { JP_CHROMIUM_FOOTER_DATA } from "./chromium/ja";
import { CN_CHROMIUM_FOOTER_DATA } from "./chromium/zh";
import { EN_VISIUM_FOOTER_DATA } from "./visium/en";
import { JP_VISIUM_FOOTER_DATA } from "./visium/ja";
import { CN_VISIUM_FOOTER_DATA } from "./visium/zh";
import { EN_XENIUM_FOOTER_DATA } from "./xenium/en";
import { JP_XENIUM_FOOTER_DATA } from "./xenium/ja";
import { CN_XENIUM_FOOTER_DATA } from "./xenium/zh";

export { EN_FOOTER_DATA } from "./en";
export { JP_FOOTER_DATA } from "./ja";
export { CN_FOOTER_DATA } from "./zh";

export const SUBSCRIBE_URL = "/subscribe";

export const PRODUCT_FOOTER_DATA: Record<
  Platform,
  Record<Locale, FooterData>
> = {
  chromium: {
    cn: CN_CHROMIUM_FOOTER_DATA,
    en: EN_CHROMIUM_FOOTER_DATA,
    jp: JP_CHROMIUM_FOOTER_DATA,
  },
  visium: {
    cn: CN_VISIUM_FOOTER_DATA,
    en: EN_VISIUM_FOOTER_DATA,
    jp: JP_VISIUM_FOOTER_DATA,
  },
  xenium: {
    cn: CN_XENIUM_FOOTER_DATA,
    en: EN_XENIUM_FOOTER_DATA,
    jp: JP_XENIUM_FOOTER_DATA,
  },
};
