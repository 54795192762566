export const CN_CHROMIUM_FOOTER_DATA = {
  title: "Chromium Single Cell",
  items: [
    {
      label: "Start Here",
      links: [
        {
          target: "_self",
          label: "Platform Overview",
          url: "/platforms/chromium",
        },
        {
          target: "_self",
          label: "Technology",
          url: "/platforms/chromium/technology",
        },
        {
          target: "_self",
          label: "Applications",
          url: "/platforms/chromium/applications",
        },
        {
          target: "_self",
          label: "Products",
          url: "/platforms/product-family",
        },
      ],
    },
    {
      label: "Chromium Products",
      links: [
        {
          target: "_self",
          label: "Flex Gene Expression",
          url: "/products/flex-gene-expression",
        },
        {
          target: "_self",
          label: "Universal 3' Gene Expression",
          url: "/products/universal-three-prime-gene-expression",
        },
        {
          target: "_self",
          label: "Universal 5' Gene Expression",
          url: "/products/universal-five-prime-gene-expression",
        },
        {
          target: "_self",
          label: "Epi Multiome",
          url: "/products/epi-multiome",
        },
        {
          target: "_self",
          label: "Epi ATAC",
          url: "/products/epi-atac",
        },
        {
          target: "_self",
          label: "Nuclei Isolation",
          url: "/products/nuclei-isolation",
        },
        {
          target: "_self",
          label: "CRISPR Screening",
          url: "/products/single-cell-crispr-screening",
        },
      ],
    },
    {
      label: "Chromium Instruments",
      links: [
        {
          target: "_self",
          label: "Chromium X Series",
          url: "/instruments/chromium-family",
        },
      ],
    },
    {
      label: "Analysis",
      links: [
        {
          target: "_self",
          label: "Chromium Analysis",
          url: "/products/chromium-analysis",
        },
        {
          target: "_self",
          label: "Cloud Analysis",
          url: "/products/cloud-analysis",
        },
      ],
    },
    {
      label: "Resources",
      links: [
        {
          target: "_self",
          label: "Chromium User Guides",
          url: "/support/user-guides",
        },
        {
          target: "_self",
          label: "Chromium Support Documentation",
          url: "/support",
        },
        {
          target: "_self",
          label: "Chromium Datasets",
          url: "/datasets?query=&page=1&configure%5BhitsPerPage%5D=50&configure%5BmaxValuesPerFacet%5D=1000&refinementList%5Bplatform%5D%5B0%5D=Chromium%20Single%20Cell",
        },
        {
          target: "_self",
          label: "Chromium Publications",
          url: "/publications?refinementList%5BproductGroups%5D%5B0%5D=Single%20Cell%20Gene%20Expression&refinementList%5BproductGroups%5D%5B1%5D=Single%20Cell%20Immune%20Profiling&refinementList%5BproductGroups%5D%5B2%5D=Single%20Cell%20ATAC&refinementList%5BproductGroups%5D%5B3%5D=Single%20Cell%20Multiome%20ATAC%20%2B%20Gene%20Expression&page=1",
        },
      ],
    },
    {
      label: "Store",
      links: [
        {
          target: "_self",
          label: "Build a Chromium Experiment",
          url: "/store/experiment-builder",
        },
      ],
    },
  ],
};
