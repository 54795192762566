import type { Locale } from "@10xdev/cms/types";
import {
  languages,
  languageToCountryMap,
} from "@10xdev/site/utils/available-locales";
import {
  layoutWidth1200,
  mediaTabletLandscape,
  sizeXlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import ISO6391 from "iso-639-1";
import { useRouter } from "next/router";
import Anchor from "../Anchor";
import Branding from "../Branding";
import Flex from "../Flex";
import Grid from "../Grid";
import Text from "../Text";

import GridItem from "../Grid/GridItem";
import ContactLinks from "./ContactLinks";
import IconLinks from "./IconLinks";
import Links from "./Links";
import type { FooterData } from "./types";
import { Theme } from "./types";
import { getDataForLocale, getThemeStyles } from "./utils";

interface Props {
  data?: FooterData;
  theme?: Theme;
  locale?: Locale;
  maxWidth?: string;
  supportLayout?: boolean;
}

const Global = ({
  theme = Theme.dark,
  locale,
  maxWidth,
  supportLayout,
}: Props) => {
  const router = useRouter() || { asPath: "" };
  const {
    backgroundColor,
    borderColor,
    textColor,
    textHoverColor,
    tmColor,
    topBorderColor,
  } = getThemeStyles(theme);

  const { items } = getDataForLocale(locale);

  const [
    company,
    platforms,
    resources,
    legal,
    managePreferences,
    contact,
    social,
  ] = items;

  const handleLocaleChange = (country: string) => {
    // https://nextjs.org/docs/advanced-features/i18n-routing#leveraging-the-next_locale-cookie
    // Set the selected language on the cookie so that the user is returned to it next time:
    document.cookie = `NEXT_LOCALE=${country}`;
  };

  return (
    <footer
      css={css`
        align-items: center;
        background-color: ${backgroundColor};
        border-top: 1px solid ${topBorderColor};
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        min-height: 480px;
        padding: ${sizeXlarge} 1rem 0;
        width: 100%;

        ${supportLayout
          ? `padding-left: 48px;
        padding-right: 48px;

        @media (max-width: ${mediaTabletLandscape}) {
          padding-left: 24px;
          padding-right: 24px;
        }`
          : ""};
      `}
    >
      <div
        css={css`
          max-width: ${maxWidth || layoutWidth1200};
          width: 100%;
          box-sizing: border-box;
        `}
      >
        <Grid
          css={css`
            padding-bottom: 2.5rem;

            @media (min-width: 900px) and (max-width: 1030px) {
              ${supportLayout ? "grid-template-columns: repeat(3, 175px)" : ""};
            }

            @media (min-width: 1201px) and (max-width: 1350px) {
              ${supportLayout ? "grid-template-columns: repeat(4, 175px)" : ""};
            }
          `}
          gap={{ base: "30px" }}
          gridTemplateColumns={{
            base: "1fr 1fr",
            tabletPortrait: supportLayout
              ? "repeat(3, 175px)"
              : "repeat(3, 175px)",
            tabletLandscape: supportLayout
              ? "repeat(4, 150px)"
              : "repeat(4, 175px)",
            desktop: supportLayout
              ? "repeat(4, 150px) auto"
              : "repeat(4, 175px) 380px",
          }}
        >
          <Flex>
            <Branding mode={theme} />
          </Flex>
          {company && <Links section={company} theme={theme} />}
          <GridItem
            css={css`
              display: flex;
              flex-direction: column;
              gap: 2rem;
              @media (min-width: 900px) and (max-width: 1030px) {
                ${supportLayout ? "grid-column-start: 2;" : ""};
              }
            `}
            gridColumnStart={{
              base: "initial",
              tabletPortrait: "2",
              tabletLandscape: "initial",
            }}
            gridRowStart={{
              base: "initial",
              tabletPortrait: "2",
              tabletLandscape: "initial",
            }}
          >
            {platforms && <Links section={platforms} theme={theme} />}
            {resources && <Links section={resources} theme={theme} />}
          </GridItem>
          <GridItem
            css={css`
              display: flex;
              flex-direction: column;
              gap: 2rem;

              @media (min-width: 900px) and (max-width: 1030px) {
                ${supportLayout
                  ? "grid-column-start: 3; grid-row-start: 1;"
                  : ""};
              }
            `}
            gridColumnStart={{
              base: "initial",
              tabletPortrait: "3",
              tabletLandscape: "initial",
            }}
          >
            {legal && <Links section={legal} theme={theme} />}
            {managePreferences && (
              <Links section={managePreferences} theme={theme} />
            )}
          </GridItem>
          <GridItem
            css={css`
              @media (min-width: 900px) and (max-width: 1030px) {
                ${supportLayout ? "grid-column-start: 2;" : ""};
              }

              @media (min-width: 1201px) and (max-width: 1350px) {
                ${supportLayout
                  ? "grid-row-start: 3; grid-column: 3 / span 2;"
                  : ""};
              }
            `}
            gridColumnStart={{
              base: "initial",
              tabletPortrait: "2",
              tabletLandscape: "3",
              desktop: "initial",
            }}
            gridRowStart={{
              base: "initial",
              tabletPortrait: "3",
              desktop: "initial",
            }}
            gridColumn={{ base: "1 / span 2", desktop: "initial" }}
          >
            <Flex flexDirection={"column"} gap={"2rem"}>
              {contact && <ContactLinks section={contact} theme={theme} />}
              {social && <IconLinks section={social} theme={theme} />}
            </Flex>
          </GridItem>
        </Grid>

        <Flex
          css={css`
            border-top: 1px solid ${borderColor};
          `}
          padding={{ base: "1.5rem 0 0 0", tabletLandscape: "0" }}
          justifyContent={{ base: "initial", tabletLandscape: "space-between" }}
          flexDirection={{ base: "column-reverse", tabletLandscape: "row" }}
        >
          <Text
            as={"div"}
            color={tmColor}
            css={css`
              padding: 1.5rem 0;
            `}
            size={"xsmall"}
          >
            {`© ${new Date().getFullYear()} 10x Genomics. All Rights Reserved.`}
          </Text>
          <Flex gap={"1rem"} alignItems={"center"}>
            {languages.map((language) => {
              const nativeName = ISO6391.getNativeName(language);
              const country = languageToCountryMap[language] as Locale;

              return (
                <Text
                  as={"span"}
                  color={textColor}
                  key={country}
                  size={"xsmall"}
                >
                  <Anchor
                    color={"inherit"}
                    hoverColor={textHoverColor}
                    // Server doesn't get hash params, so it makes an error
                    // when the client does see the hash params
                    href={router.asPath.split("#")[0]}
                    locale={country}
                    onClick={() => handleLocaleChange(country)}
                  >
                    {nativeName}
                  </Anchor>
                </Text>
              );
            })}
          </Flex>
        </Flex>
      </div>
    </footer>
  );
};

export default Global;
