import { css } from "@emotion/react";

import Anchor from "../Anchor";
import Grid from "../Grid";
import Text from "../Text";
import { Theme } from "./types";
import { getThemeStyles } from "./utils";

interface Props {
  section: {
    label: string;
    links: Array<{
      label: string;
      target?: string;
      url: string;
      className?: string;
    }>;
  };
  theme?: Theme;
  highlight?: boolean;
}

const Links = ({ section, theme = Theme.dark, highlight }: Props) => {
  const { links } = section;
  const { textColor, textHoverColor, labelColor, highlightColor } =
    getThemeStyles(theme);
  return (
    <Grid
      css={css`
        align-self: start;
        width: 100%;
      `}
      gap={"1rem"}
    >
      {section.label && (
        <Text as={"h3"} color={labelColor} size={"xsmall"} weight={"semibold"}>
          {section.label}
        </Text>
      )}

      <ul
        css={css`
          display: grid;
          gap: 1rem;
          list-style: none;
          margin: 0;
          border-radius: ${highlight ? "6px" : "0"};
          background-color: ${highlight ? highlightColor : "transparent"};
          padding: ${highlight ? "1rem" : "0"};
        `}
      >
        {links?.map((link) => {
          const { label, target, url, className } = link;

          return (
            <li key={label}>
              <Anchor
                color={textColor}
                hoverColor={textHoverColor}
                href={url || "/"}
                target={target || undefined}
                className={className}
              >
                <Text as={"span"} color={"inherit"} size={"xsmall"}>
                  {label}
                </Text>
              </Anchor>
            </li>
          );
        })}
      </ul>
    </Grid>
  );
};

export default Links;
